import React from "react";
import styles from "./Preloader.module.scss";
import { preloader } from "../../../utils/icons";


type Props = {
    width?: number | string;
    height?: number | string;
};

const Preloader: React.FC<Props> = ({ width, height }) => {
    const PreloaderIcon = preloader as React.ElementType;

    return (
        <div className={styles.container}>
            <PreloaderIcon
                className={styles.preloader}
                style={{
                    width: width ? width : "53.33px",
                    height: height ? height : "53.33px",
                }}
            />
        </div>
    );
};

export default Preloader;
