

type Props = {
    showBackBtn?: false;
};

const Footer: React.FC<Props> = ({ showBackBtn = true }) => {
return (
    <div className="w-full flex mx-auto pt-6 max-w-[1360px]">
        <div
            className="w-full flex flex-col gap-[35px] md:gap-3 xl:gap-6 pt-8 pb-9 lg:flex-row flex-wrap px-5 md:px-10 lg:px-20 justify-between items-center"
        >
            <hr className="w-full border-t border-gray/10" />
            <div
                className="flex flex-col md:flex-row gap-[18px] md:gap-8 items-center justify-center"
            >
                <div className="w-auto flex flex-row items-center justify-center gap-3">
                    <img
                        src="./ppmpnc.png"
                        className="h-[34px] w-auto"
                        alt="National Emblem Black and White"
                    />
                    <div className="w-auto flex flex-row items-center justify-start gap-2">
                        <p
                            className="font-normal font-inter text-base leading-5 text-[#6D7494] text-center"
                        >
                            © 2024
                        </p>
                        <p
                            className="font-normal not-italic font-faseyha text-base leading-5 text-[#6D7494] text-center"
                        >
                            މ, މުސްޠަފާ އާބާދު
                        </p>
                    </div>
                </div>
                <div className="w-auto flex flex-row items-center justify-center gap-4 sm:gap-8">
                    <div className="w-auto flex flex-row items-center justify-start gap-[10px]">
                        <p
                            className="font-normal not-italic font-faseyha text-base leading-5 text-[#6D7494] text-center"
                        >
                            ސަބުދެލި މަގު، މާލެ
                        </p>
                        {/* <!-- <p
                            className="font-normal font-inter text-base leading-5 text-[#6D7494] text-center"
                        >
                            20113
                        </p> --> */}
                    </div>
                    {/* <!-- <p
                        className="font-normal font-inter text-base leading-5 text-[#6D7494] text-center"
                    >
                        |
                    </p>
                    <div className="w-auto flex flex-row items-center justify-start gap-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                        >
                            <path
                                stroke="#6D7494"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M6.712 3.75A3.75 3.75 0 0 0 3.75 6.713M6.712.75A6.75 6.75 0 0 0 .75 6.705m.75 5.985v2.25a1.5 1.5 0 0 0 1.635 1.5 14.843 14.843 0 0 0 6.472-2.303 14.625 14.625 0 0 0 4.5-4.5 14.843 14.843 0 0 0 2.303-6.502A1.5 1.5 0 0 0 14.917 1.5h-2.25a1.5 1.5 0 0 0-1.5 1.29 9.627 9.627 0 0 1-.525 2.107 1.5 1.5 0 0 0 .338 1.583l.952.952a12 12 0 0 1-4.5 4.5l-.952-.952a1.5 1.5 0 0 0-1.583-.338c-.68.254-1.387.43-2.107.526A1.5 1.5 0 0 0 1.5 12.69Z"
                            ></path>
                        </svg>
                        <p
                            className="font-normal font-inter text-base leading-5 text-[#6D7494] text-center"
                        >
                            3323701
                        </p>
                    </div> --> */}
                </div>
            </div>
            <div className="lg:mr-2 flex flex-row gap-4 sm:gap-2 items-center justify-center">
                <a href="https://www.facebook.com/mustho2024" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                    <svg className="opacity-70 h-[34px] w-auto" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                        <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"></path>
                    </svg>
                </a>
                <a href="https://twitter.com/mustho2024" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                    <svg className="p-[2px] opacity-70 h-[34px] w-auto"  version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24" viewBox="0 0 174.000000 174.000000"
                    preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,174.000000) scale(0.100000,-0.100000)"
                        fill="#000000" stroke="none">
                        <path d="M705 1696 c-162 -38 -310 -118 -420 -230 -103 -103 -176 -228 -217
                        -372 -30 -104 -32 -323 -4 -424 88 -319 333 -553 645 -616 100 -20 283 -15
                        376 10 33 9 106 39 162 66 165 81 313 236 388 405 89 202 91 455 5 664 -94
                        228 -301 412 -542 482 -95 27 -305 35 -393 15z m92 -511 l131 -175 162 175
                        162 175 45 0 45 0 -100 -107 c-237 -257 -271 -296 -265 -307 3 -6 96 -131 205
                        -278 109 -146 198 -269 198 -272 0 -3 -67 -6 -149 -6 l-149 0 -143 191 c-135
                        181 -144 190 -158 172 -9 -10 -87 -96 -175 -191 l-158 -172 -45 0 -45 0 80 87
                        c44 49 132 144 196 213 63 69 116 130 116 135 0 6 -64 96 -142 200 -78 104
                        -165 222 -195 262 l-54 73 153 0 153 0 132 -175z"/>
                        <path d="M506 1278 c4 -7 142 -194 307 -415 l299 -403 69 0 70 0 -38 53 c-21
                        29 -159 216 -308 415 l-269 362 -69 0 c-52 0 -66 -3 -61 -12z"/>
                        </g>
                    </svg>
                </a>
                <a href="https://www.instagram.com/mustho_24" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                    <svg className="p-[2px] opacity-70 h-[34px] w-auto" fill="#000000" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="800px" height="800px" viewBox="0 0 260 260" enableBackground="new 0 0 260 260" xmlSpace="preserve">
                    <path d="M164.58,64H95.42C78.1,64,64,78.1,64,95.42v69.16C64,181.9,78.1,196,95.42,196h69.16c17.32,0,31.42-14.1,31.42-31.42V95.42
                        C196,78.1,181.9,64,164.58,64z M130,171.1c-22.66,0-41.1-18.44-41.1-41.1s18.44-41.1,41.1-41.1s41.1,18.44,41.1,41.1
                        S152.66,171.1,130,171.1z M172.22,97.3c-5.3,0-9.6-4.3-9.6-9.61c0-5.3,4.3-9.6,9.6-9.6c5.31,0,9.61,4.3,9.61,9.6
                        C181.83,93,177.53,97.3,172.22,97.3z M130,102.9c-14.94,0-27.1,12.16-27.1,27.1s12.16,27.1,27.1,27.1s27.1-12.16,27.1-27.1
                        S144.94,102.9,130,102.9z M130,2C59.31,2,2,59.31,2,130s57.31,128,128,128s128-57.31,128-128S200.69,2,130,2z M210,164.58
                        c0,25.04-20.38,45.42-45.42,45.42H95.42C70.38,210,50,189.62,50,164.58V95.42C50,70.38,70.38,50,95.42,50h69.16
                        C189.62,50,210,70.38,210,95.42V164.58z"/>
                    </svg>
                </a>
                {/* <a
                    className="font-normal not-italic font-faseyha text-base leading-5 text-[#6D7494] text-center"
                    href="https://citizensvoice.gov.mv/privacy"
                    >ޕްރައިވެސީ ޕޮލިސީ</a
                >
                <div className="h-[3px] w-[3px] bg-[#C4C8D4] rounded-[10px]"></div>
                <a
                    className="font-normal not-italic font-faseyha text-base leading-5 text-[#6D7494] text-center"
                    href="https://citizensvoice.gov.mv/terms"
                    >ޓަރމްސް އޮފް ޔޫސް</a
                > */}
            </div>
        </div>
    </div>
);
}
export default Footer;