const statsData = {
    Submitted: 120,
    OnGoing: 540,
    Completed: 440
}

const rollingText = "މުސްތޮއަކީ އިނގިރޭސި ވިލާތުގެ އެންގިލިއާ ރަސްކިން ޔުނިވަރސިޓީން މާރސްޓަރ އޮފް ބިޒްނަސް އެޑްމިނިސްޓްރޭޝަން ހާސިލްކޮށްފައިވާ، މާފަންނު ހުޅަނގުދާއިރާގައި ދިރިއުޅޭ ދާއިރާގެ އިލްމީ ބޭފުޅެކެވެ.";

export const Constants = {
    Stats: statsData,
    Text: rollingText
}