/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
// import { useNavigate } from "react-router-dom";
import styles from "./Profile.module.scss";
import mustho from '../../assets/images/mustho copy-03.png'
import dhivehingeMajilis from '../../assets/images/majlis logo white.png'
import photo1 from '../../assets/images/mustho copy-10.png'
import photo2 from '../../assets/images/ppm pnc logo-01.png'
import { Link } from "react-router-dom";
import TopBar from "../../components/shared/Topbar/TopBar";

const Profile: React.FC = () => {
    return (
        <div
            className={styles.home}
            // data-theme={theme}
        >
            <TopBar />
            <div className={styles.home__users}>
                <main className="mx-auto w-ful pb-6 md:pb-14">
                    <div className="mx-auto max-w-7xl items-center justify-center px-5 lg:px-0">
                        <div className="w-full flex flex-col gap-[146px] justify-center items-center">
                            <div className="w-full max-w-[764px] flex flex-col gap-[56px] justify-center items-center">
                                <Link className="w-full flex flex-col gap-8 md:gap-[42px] justify-center items-center" to={"/profile"}>
                                    {/* <p className="font-waheed text-[20px] md:text-[32px] tracking-[0.2px] md:tracking-[0.32px] leading-[30px] text-[#858BA5] text-center" style={{color: "rgb(207, 207, 207)"}}>
                                        މަޝްވަރާގައި ބައިވެރިވެވަޑައިގަންނަވާ
                                    </p> */}
                                    <p className="font-normal font-aamu text-[34px] md:text-[52px] leading-[58px] md:leading-[82px] text-black text-center">
                                        މުސްތޮއާއެކީ ޤައުމުގެ މުސްތަގުބަލު ބައްޓަންކުރުމުގައި
                                        ބައިވެރިވެވަޑައިގަންނަވާ
                                    </p>
                                    <img src={mustho}
                                        className="flex-none h-[auto] w-[300px] aspect-ratio"
                                        alt="mustho main photo"/>

                                    <div className="mx-auto flex-wrap-center-sm flex gap-6 max-w-7xl items-center justify-center px-5">
                                        <img src={dhivehingeMajilis}
                                            style={{maxHeight: "200px"}}
                                            className="flex-none  w-[auto] aspect-ratio"
                                            alt="mustho main photo"/>

                                        <img src={photo2}
                                            className="flex-none h-[auto] w-[180px] aspect-ratio"
                                            alt="mustho main photo"/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="mx-auto pt-20 px-5 max-w-7xl max-w-[764px]">
                        {/* <img
                            src="./ރައްޔިތުންގެ އަޑު_files/president_dr_muizzu.webp"
                            className="flex-none h-[50px] w-[50px] aspect-square rounded-full"
                            alt=""
                        />
                        <p className="font-faseyha text-lg lg:text-[23px] text-gray/70">
                            ރައީސުލްޖުމްހޫރިއްޔާ ޑޮކްޓަރ މުޙައްމަދު މުޢިއްޒުގެ އިސްނެންގެވުމެއް
                        </p> */}

                        <p className="font-faseyha text-lg lg:text-[23px] text-gray/70 leading-10 pb-10">
                        މުސްތޮއަކީ އިނގިރޭސި ވިލާތުގެ އެންގިލިއާ ރަސްކިން ޔުނިވަރސިޓީން މާރސްޓަރ އޮފް ބިޒްނަސް އެޑްމިނިސްޓްރޭޝަން ހާސިލްކޮށްފައިވާ، މާފަންނު ހުޅަނގުދާއިރާގައި ދިރިއުޅޭ ދާއިރާގެ އިލްމީ ބޭފުޅެކެވެ.
                        </p>
                        <p className="font-faseyha text-lg lg:text-[23px] text-gray/70 leading-10 pb-10">
                        އެބޭފުޅާއަކީ ތާޖުއްދީން ސްކޫލްގަޔާއި އަދި އޭގެފަހުން އަމީނިއްޔާ ސްކޫލުގައި މުދައްރިސެއްގެ ގޮތުގައި އަދި ކުއްލިއްޔަތުއްދިރާސާތުލް އިސްލާމިއްޔާއާއި، ގައުމީ ޔުނިވަރސިޓީގައި ލެކްޗަރާއެއްގެ ގޮތުން ކިޔަވައިދެއްވާފައިވާ ގިނަ ދަރިވަރުންތަކެއްގެ މުސްތަފާ ސާރއެވެ. ކިޔަވައި ދިނުމުގެ އިތުރުން މުސްތޮއަކީ ހިންގުމުގެ ދާއިރާގައި މިނިސްޓްރީ އޮފް ޕްލޭނިންގް އެންޑް ޑިވެލޮޕްމަންޓްގެ ޑެޕިއުޓީ ޑިރެކްޓަރކަމާއި، ޑިރެކްޓަރ ޖެނެރަލްކަމާއި، މުލަކަތޮޅުގެ އަތޮޅުވެރިކަމާއި މިނިސްޓްރީ އޮފް އިސްލާމިކް އެފެއަރޒްގެ ޑެޕިއުޓީ މިނިސްޓަރުކަމާއި، މިނިސްޓްރީ އޮފް އެޑިއުކޭޝަންގެ ސީނިއަރ ޕޮލިސީ އެގްޒެކްޓިވްކަމުގެ މަޤާމު އަދާކޮށްފައިވާ، ހިންގުމު ދާއިރާގެ ތަޖުރިބާކާރެކެވެ. މީގެ އިތުރުން ދިވެހި ބަހުގެ ގަވާޢިދު ކޮމެޓީގެ މެންބަރުކަމާއި، އިސްލާމިކް ޔުނިވާސިޓީގެ ކައުންސިލް މެންބަރުކަމާއި، ޒަކާތު ކޮމެޓީގެ ޗެއާމަންކަމާއި، އިސްލާމިކް ޑިވެލޮޕްމެންޓް ބޭންކު (އައި.ޑީ.ބީ)ގެ ދިވެހިރާއްޖޭގެ އަލްޓަނޭޓް ގަވަނަރކަން އަދާ ކޮށްފައިވެއެވެ.
                        </p>

                        <p className="font-faseyha text-lg lg:text-[23px] text-gray/70 leading-10 pb-10">
                        މުސްތޮއަކީ މީގެ ކުރިން ވެސް ރައްޔިތުންގެ މަޖިލީހަށް ހުށައެޅޭ ބިލުތައް އިޞްލާޙުކުރުމުގެ މަސައްކަތުގައި އެޓަރނީ ޖެނެރަލް އޮފީހުގައި، އޭރުގެ އެޓަރނީ ޖެނެރަލުން ކަމުގައިވާ އަލްއުސްތާޛު ޑރ.ހަސަން ސަޢީދާއި އަލްއުސްތާޛާ ޢާއިޝަތު އާޒިމާ ޝަކޫރުގެ އަރިހުގައި މަސައްކަތް ކުރައްވާފައިވާ ބޭފުޅެކެވެ. އަދި މިހާރު ޢަމަލުކުރަމުންދާ ފެހި ޤާނޫނު އަސާސީ ވުޖޫދަށް އުފަންކުރުމުގެ މަސައްކަތް މަޝްހޫރު ޤާނޫނީ ވަކީލު އަލްއުސްތާޛު މޫސާ ފަޠުޙީއާއެކު ވެސް މަސައްކަތް ކުރައްވާފައިވާ ގާނޫނުތަކަށް ފަރިތަ ބޭފުޅެކެވެ.
                        </p>
                    </div>

                    <div className="w-full flex flex-col gap-[56px] justify-center items-center">
                        <a
                            className="bg-login-button p-[2px] rounded-[100px] loginBtn hover:shadow-login-button transition-all"
                            type="submit"
                            href="/CV mustho.pdf"
                        >
                            <span className="flex flex-row gap-1 font-waheed text-[19px] leading-6 tracking-wide bg-white rounded-[100px] py-3 pr-5 pl-[22px] text-gray-[50] loginBtn-hover:bg-transparent hover:bg-transparent hover:text-[#E5FFFE] loginBtnText transition-all gap-3">

                            {/* bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" */}
                                ސީވީ ޑައުންލޯޑް ކުރައްވާ
                                <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" color="gray"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                            </span>
                        </a>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Profile;
