import logo from "./logo.svg";
import number from "../../../assets/images/number-03.png";
import mainImg from "../../../assets/images/mustho copy-09.png";
import { Link } from "react-router-dom";
import styles from "./TopBar.module.scss";
import { Constants } from "../../../data/elements";

type Props = {
    showBackBtn?: false;
};

const TopBar: React.FC<Props> = ({ showBackBtn = true }) => {
    return (
        <div className="w-full z-10 mx-auto p-5 sticky top-0 bg-transparent">
            <div className="mx-auto max-w-7xl items-center justify-center">
                <div className="w-full flex flex-row justify-between items-center gap-[18px] py-3 pl-5 pr-6 rounded-[20px] bg-blue-light backdrop-blur-[15px] webkit-backdrop-blur-15">
                    <div
                        className="w-full font-waheed text-[21px] md:text-[31px] leading-8 flex"
                        // href="/"
                    >
                        <img
                            src={number}
                            className="h-[42px] md:h-[50px] w-auto"
                            alt="ރައްޔިތުންގެ އަޑު"
                        />
                        <img
                            src={mainImg}
                            className="h-[42px] md:h-[50px] w-auto hide-sm"
                            alt="ރައްޔިތުންގެ އަޑު"
                        />

                        {<div className={styles.marquee}>
                            <p>
                            {Constants.Text}
                            </p>
                        </div>}
                    </div>
                    {showBackBtn && (
                        <Link to="/">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                            >
                                <path
                                    fill="currentColor"
                                    d="m3.22 12.53 6.75 6.75a.75.75 0 0 0 1.06-1.06l-5.47-5.47h14.69a.75.75 0 1 0 0-1.5H5.56l5.47-5.47a.75.75 0 1 0-1.06-1.06l-6.75 6.75a.75.75 0 0 0 0 1.06Z"
                                ></path>
                            </svg>
                        </Link>
                    )}
                    {/* <div className="flex flex-row gap-5 items-center justify-self-end shrink-0 relative group cursor-pointer">
                <div
                    className="flex flex-row items-center gap-4"
                    role="button"
                    tabIndex={0}
                >
                    <div className="md:hidden flex-row items-center gap-2 hidden">
                        <svg
                            className="inline-block h-4 w-4 inline align-sub transition group-hover:rotate-180"
                            aria-hidden="true"
                        >
                            <use href="/assets/icons-sprite.svg#icon-chevron-down"></use>
                        </svg>
                        <p className="font-inter font-medium text-base text-gray">
                            Ali Affaz
                        </p>
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="none"
                    >
                        <path
                            stroke="#084BFC"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeOpacity=".7"
                            strokeWidth="2"
                            d="M16 6C10.477 6 6 10.477 6 16s4.477 10 10 10 10-4.477 10-10S21.523 6 16 6Z"
                        ></path>
                        <path
                            stroke="#084BFC"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeOpacity=".7"
                            strokeWidth="2"
                            d="M8.271 22.346s2.23-2.846 7.73-2.846 7.729 2.846 7.729 2.846M16 16a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                        ></path>
                    </svg>
                </div>
                <div className="dropdown-content bg-white rounded-xl p-2 text-black h-auto w-[180px] shadow-lg items-start justify-start absolute top-full left-0 md:group-hover:block z-10 hidden">
                    <p className="font-inter text-left p-2 pb-3 font-medium text-base text-gray border-b border-[rgba(11,24,76,0.07)]">
                        Ali Affaz
                    </p>
                    <a
                        href="https://account.one.gov.mv"
                        className="p-2 mt-2 hover:bg-[#E3F2F6] rounded-xl text-left flex items-center justify-center"
                    >
                        <span className="w-full">User Profile</span>
                    </a>
                    <form action="/logout" method="post">
                        <button
                            className="w-full box-border px-2 pt-[10px] pb-[7px] hover:bg-[#E3F2F6] rounded-xl flex"
                            type="submit"
                        >
                            <span className="w-full flex flex-row gap-2 text-red-600 items-start justify-end">
                                <p>Log Out</p>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="none"
                                >
                                    <path
                                        fill="#CC0D0D"
                                        d="m16.875 10-3.125 3.125v-6.25L16.875 10Z"
                                        opacity=".2"
                                    ></path>
                                    <path
                                        fill="#CC0D0D"
                                        d="M8.75 16.875a.625.625 0 0 1-.625.625H3.75a1.25 1.25 0 0 1-1.25-1.25V3.75A1.25 1.25 0 0 1 3.75 2.5h4.375a.625.625 0 0 1 0 1.25H3.75v12.5h4.375a.625.625 0 0 1 .625.625Zm8.567-6.433-3.125 3.125a.625.625 0 0 1-1.067-.442v-2.5h-5a.625.625 0 1 1 0-1.25h5v-2.5a.625.625 0 0 1 1.067-.442l3.125 3.125a.626.626 0 0 1 0 .884ZM15.99 10l-1.614-1.616v3.232L15.989 10Z"
                                    ></path>
                                </svg>
                            </span>
                        </button>
                    </form>
                </div>
            </div> */}
                </div>

                {/* <div
        className="w-full flex flex-row justify-between items-center gap-[18px] py-3 pl-5 pr-6 rounded-[20px] bg-blue-light backdrop-blur-[15px] webkit-backdrop-blur-15"
    >
        <a
            className="w-full font-waheed text-[21px] md:text-[31px] leading-8"
            href="https://citizensvoice.gov.mv/"
            ><img
                src={number}
                className="h-[42px] md:h-[50px] w-auto"
                alt="ރައްޔިތުންގެ އަޑު"
        /></a>
        <div
            className="flex flex-row gap-5 items-center justify-self-end shrink-0 relative group cursor-pointer"
        ></div>
    </div> */}
            </div>
        </div>
    );
};
export default TopBar;
