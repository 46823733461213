import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import styles from "./Select.module.scss";

type Props = {
    optionsList: string[];
    labelName: string;
    isLabelInline?: boolean;
    icon?: React.ReactNode;
    showNewElement?: boolean;
    newElementIcon?: React.ReactNode;
    newElementName?: string;
    newElementOnClick?: () => void;
    onElementSelected?: (index: number, option: string) => void;
    isDisabled?: boolean;
    hasHiddenPart?: boolean;
    className?: string;
    defaultSelectedIndex?: number;
    disabledFirst?: boolean;
};

const CustomSelect = forwardRef<HTMLUListElement, Props>(
    (
        {
            optionsList,
            icon,
            labelName,
            isLabelInline,
            showNewElement,
            newElementIcon,
            newElementName,
            newElementOnClick,
            onElementSelected,
            isDisabled = false,
            hasHiddenPart = true,
            className,
            defaultSelectedIndex = 0,
            disabledFirst = false,
        },
        ref
    ) => {
        const [selectedValue, setSelectedValue] = useState<string>(optionsList[defaultSelectedIndex]);
        const [isOpen, setIsOpen] = useState<boolean>(false);
        const dropdownRef = useRef<HTMLDivElement | null>(null);

        useEffect(() => {
            if (defaultSelectedIndex && optionsList && optionsList.indexOf(selectedValue) !== defaultSelectedIndex) {
                setSelectedValue(optionsList[defaultSelectedIndex]);
            }
        }, [defaultSelectedIndex]);

        const handleSelect = (value: string) => {
            setSelectedValue(value);
            setIsOpen(false);
        };

        const toggleDropdown = () => {
            setIsOpen(!isOpen);
        };

        const handleItemClick = (e: React.MouseEvent, option: string, index: number) => {
            e.preventDefault();
            if (option) {
                handleSelect(option);
                if (onElementSelected) onElementSelected(index, option);
            }
        };

        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                    setIsOpen(false);
                }
            };

            document.addEventListener("click", handleClickOutside);

            return () => {
                document.removeEventListener("click", handleClickOutside);
            };
        }, [isOpen]);

        return (
            <div
                className={`${styles.container} ${isLabelInline ? styles.inline : ""} ${className ? className : ""}`}
                data-component="select"
            >
                {labelName && <h5>{labelName}</h5>}
                <div
                    className={`${styles["select-dropdown"]} ${isOpen ? styles.active : ""}`}
                    ref={dropdownRef}
                >
                    <button
                        type="button"
                        disabled={isDisabled}
                        role="button"
                        data-value={selectedValue}
                        className={`${styles["select-dropdown__button"]} ${isOpen ? styles.active : ""}`}
                        onClick={toggleDropdown}
                    >
                        {icon && <span>{icon}</span>}

                        <span>
                            {optionsList && optionsList.length > 0
                                ? optionsList.find((option) => option === selectedValue) || optionsList[0]
                                : ""}
                        </span>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#0b184c66"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                    </button>
                    <ul
                        className={`${styles["select-dropdown__list"]} ${isOpen ? styles.active : ""}`}
                        ref={ref}
                    >
                        {(showNewElement || newElementName || newElementIcon) && (
                            <li
                                className={styles["select-dropdown__list-new"]}
                                onClick={newElementOnClick}
                            >
                                <span>{newElementIcon}</span>
                                <p>{newElementName}</p>
                            </li>
                        )}
                        {optionsList.map((option, index) => {
                            const itemDisabled = disabledFirst && index === 0;

                            return (
                                <li
                                    data-key={index}
                                    key={`${option}: ${index}`}
                                    data-value={option.toLowerCase()}
                                    className={`${styles["select-dropdown__list-item"]} ${
                                        selectedValue === option ? styles.selected : ""
                                    }`}
                                    onClick={(e) => {
                                        if (!itemDisabled) handleItemClick(e, option, index);
                                    }}
                                >
                                    {icon && <span>{icon}</span>}
                                    {hasHiddenPart && "*"}
                                    {option}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
);

CustomSelect.displayName = "CustomSelect";
export default CustomSelect;
