/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import styles from "./Home.module.scss";
import mustho from '../../assets/images/mustho copy-03.png'
import dhivehingeMajilis from '../../assets/images/back-01.png'
import { Link } from "react-router-dom";
import { isNull } from "util";


type Props = {
    endNumber: number;
    speed?: number
};


const NumberIncrementor: React.FC<Props> = ({endNumber = 10, speed = 10}) => {
    let element = useRef<HTMLHeadingElement | null>(null);

    // /* Call this function with a string containing the ID name to
    // * the element containing the number you want to do a count animation on.
    // *
    // * Speed of animation (milliseconds timeout between each number increase)
    // */
    // function increaseNumberAnimation(elementId, endNumber, speed = 10) {
    function increaseNumberAnimation() {

        if(!element.current) return

        /* A dataset variable that is added to the animated element*/
        const animationRunning = JSON.parse(element.current.dataset.animationRunning ?? "false")

        if(animationRunning) return

        element.current.dataset.animationRunning = "true";

        incNbrRec(0)
    }

    /*A recursive function to increase the number.*/
    function incNbrRec(currentNumber: number) {
        if (!element.current) return;

        if (currentNumber <= endNumber) {
        element.current.innerHTML = currentNumber.toString()
        setTimeout(function() {
            incNbrRec(currentNumber + 1)
        }, speed) //Delay a bit before calling the function again.
        } else {
            element.current.dataset.animationRunning = "false";
        }
    }


    useEffect(() => {
        increaseNumberAnimation();
    }, []);

    return (
        <>
            <h1 className="text-[32px] font-bold" ref={element}>0</h1>
        </>
    );
};

export default NumberIncrementor;
